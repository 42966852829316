//==================================================================
// // // ALL
import { contentObjectHome } from './pageContent/home/contentObjectHome';
import { contentObjectAuszeit } from './pageContent/auszeit/contentObjectAuszeit';
import { contentObjectBlog } from './pageContent/blog/contentObjectBlog';
import { contentObjectStory } from './pageContent/story/contentObjectStory';
import { contentObjectWomo } from './pageContent/womo/contentObjectWomo';
import { contentObjectTechnik } from './pageContent/technik/contentObjectTechnik';
import { contentObjectSocialMedia } from './pageContent/socialMedia/contentObjectSocialMedia';
import { contentObjectMap } from '../data/mapData/contentObjectMap';
//==================================================================

export const contentObject = Object.assign(
  contentObjectHome,
  contentObjectAuszeit,
  contentObjectBlog,
  contentObjectStory,
  contentObjectWomo,
  contentObjectTechnik,
  contentObjectSocialMedia,
  contentObjectMap
);
