import React, { useState, useEffect, useRef } from 'react';
import LeafletMap from '../map/LeafletMap';
import classes from './Map.module.css';
import classesMy from './MapMyPos.module.css';
import MetaData from '../utils/MetaData';
// react router
import { useSearchParams, useParams } from 'react-router-dom';

import LeafletMyPos from '../map/LeafletMyPos';
import allBlogPosts from '../pages/AllBlogPosts.module.css';
import dataAbove11m from '../../data/mapData/camping/data11.json';

import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';

import defaultIcon from '../../assets/mapImg/freepik/default.png';
import pos from '../../assets/mapImg/icon/pos.png';
import future from '../../assets/mapImg/icon/greyDot.png';
import star from '../../assets/mapImg/freepik/star.png';
import van from '../../assets/mapImg/flaticon/camper.png';
import water from '../../assets/mapImg/flaticon/water.png';
import camperVan from '../../assets/mapImg/flaticon/camper-van.png';
import goldenGate from '../../assets/mapImg/flaticon/golden-gate.png';
import free from '../../assets/mapImg/flaticon/free.png';
import parking from '../../assets/mapImg/freepik/parking.png';

const MapMyPos = () => {
  //==================================================================
  const { params } = useSearchParams();

  //==================================================================
  return (
    <div className={classes.map}>
      <MetaData
        checked="omit"
        title="WomoDaddy Unser aktueller Standort und unsere gefahrene Route"
        description="Übersicht alle unsere Stellplätze entlang unserer Route quer durch Europa und darüber hinaus. Es gibt viele weitere Informationen, die auch für dich nützlich..."
      />
      <h1>
        <img src={pos} alt="Icon" className={classesMy.imgIconHead} />
        &nbsp; Aktueller Standort und gefahrene bzw. geplante Route
      </h1>
      <br />
      {/* <h3>
        Diese Karte zeigt alle Stellplätze entlang unserer Route, besondere
        Plätze sind mit einem Stern gekennzeichnet.
      </h3> */}
      {/* <h4>
        Durch klick auf die einzelnen Punkte erhältst, du mehr Informationen.
      </h4> */}
      <div className={classesMy.iconOuterBox}>
        <div className={classesMy.iconDiv}>
          <img src={camperVan} alt="Icon" className={classesMy.imgIcon} />
          <p>Stellplatz</p>
        </div>
        <div className={classesMy.iconDiv}>
          <img src={van} alt="Icon" className={classesMy.imgIcon} />
          <p>Campingplatz</p>
        </div>
        <div className={classesMy.iconDiv}>
          <img src={free} alt="Icon" className={classesMy.imgIcon} />
          <p>Freistehplatz</p>
        </div>
        <div className={classesMy.iconDiv}>
          <img src={parking} alt="Icon" className={classesMy.imgIcon} />
          <p>Parkplatz</p>
        </div>
        <div className={classesMy.iconDiv}>
          <img src={water} alt="Icon" className={classesMy.imgIcon} />
          <p>Versorgungsstation</p>
        </div>
        <div className={classesMy.iconDiv}>
          <img src={star} alt="Icon" className={classesMy.imgIcon} />
          <p>Favorit</p>
        </div>
        <div className={classesMy.iconDiv}>
          <img src={defaultIcon} alt="Icon" className={classesMy.imgIcon} />
          <p>Standard</p>
        </div>
        <div className={classesMy.iconDiv}>
          <img src={future} alt="Icon" className={classesMy.imgIcon} />
          <p>Liegt noch vor uns</p>
        </div>
        <div className={classesMy.iconDiv}>
          <img src={pos} alt="Icon" className={classesMy.imgIcon} />
          <p>Aktuelle oder Letzte Position</p>
        </div>
      </div>
      <br />
      <div className={`${classes.leafletOuterBox}`}>
        <div className={`${classes.leafletBox} leaflet-container`}>
          <LeafletMyPos></LeafletMyPos>
        </div>
      </div>
    </div>
  );
};

export default MapMyPos;
