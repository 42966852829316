import { stringClean } from '../../utils/mapFunctions.js';

import L from 'leaflet';
import defaultIcon from '../../assets/mapImg/freepik/default.png';
import pos from '../../assets/mapImg/icon/pos.png';
import greyDot from '../../assets/mapImg/icon/greyDot.png';
import star from '../../assets/mapImg/freepik/star.png';
import van from '../../assets/mapImg/flaticon/camper.png';
import water from '../../assets/mapImg/flaticon/water.png';
import camperVan from '../../assets/mapImg/flaticon/camper-van.png';
import goldenGate from '../../assets/mapImg/flaticon/golden-gate.png';
import free from '../../assets/mapImg/flaticon/free.png';
import parking from '../../assets/mapImg/freepik/parking.png';

export const icon = (string, iconSize, popupAnchor) => {
  const cleanString = stringClean(string);

  if (cleanString === 'default') {
    return new L.Icon({
      iconUrl: defaultIcon,
      iconRetinaUrl: defaultIcon,
      popupAnchor: popupAnchor ? popupAnchor : [(-0, -0)],
      iconSize: iconSize ? iconSize : [(15, 15)],
    });
  }
  if (cleanString === 'star') {
    return new L.Icon({
      iconUrl: star,
      iconRetinaUrl: star,
      popupAnchor: popupAnchor ? popupAnchor : [-0, -0],
      iconSize: iconSize ? iconSize : [30, 30],
    });
  }
  if (cleanString === 'current') {
    return new L.Icon({
      iconUrl: pos,
      iconRetinaUrl: pos,
      popupAnchor: popupAnchor ? popupAnchor : [-0, -0],
      iconSize: iconSize ? iconSize : [70, 70],
    });
  }
  if (cleanString === 'stellplatz') {
    return new L.Icon({
      iconUrl: camperVan,
      iconRetinaUrl: camperVan,
      popupAnchor: popupAnchor ? popupAnchor : [-0, -0],
      iconSize: iconSize ? iconSize : [30, 30],
    });
  }
  if (cleanString === 'campingplatz') {
    return new L.Icon({
      iconUrl: van,
      iconRetinaUrl: van,
      popupAnchor: popupAnchor ? popupAnchor : [-0, -0],
      iconSize: iconSize ? iconSize : [35, 35],
    });
  }
  if (cleanString === 'brücke') {
    return new L.Icon({
      iconUrl: goldenGate,
      iconRetinaUrl: goldenGate,
      popupAnchor: popupAnchor ? popupAnchor : [-0, -0],
      iconSize: iconSize ? iconSize : [25, 25],
    });
  }
  if (cleanString === 'freistehplatz') {
    return new L.Icon({
      iconUrl: free,
      iconRetinaUrl: free,
      popupAnchor: popupAnchor ? popupAnchor : [-0, -0],
      iconSize: iconSize ? iconSize : [25, 25],
    });
  }
  if (cleanString === 'parkplatz') {
    return new L.Icon({
      iconUrl: parking,
      iconRetinaUrl: parking,
      popupAnchor: popupAnchor ? popupAnchor : [-0, -0],
      iconSize: iconSize ? iconSize : [25, 25],
    });
  }
  if (cleanString === 'versorgungsstation') {
    return new L.Icon({
      iconUrl: water,
      iconRetinaUrl: water,
      popupAnchor: popupAnchor ? popupAnchor : [-0, -0],
      iconSize: iconSize ? iconSize : [30, 30],
    });
  }
  if (cleanString === 'grey') {
    return new L.Icon({
      iconUrl: greyDot,
      iconRetinaUrl: greyDot,
      popupAnchor: popupAnchor ? popupAnchor : [-0, -0],
      iconSize: iconSize ? iconSize : [10, 10],
    });
  }
  console.log(`❌ Error Map Icon misspelling: ${cleanString}`);
  return new L.Icon({
    iconUrl: defaultIcon,
    iconRetinaUrl: defaultIcon,
    popupAnchor: popupAnchor ? popupAnchor : [(-0, -0)],
    iconSize: iconSize ? iconSize : [(15, 15)],
  });
};
