//==================================================================
// // // Home
import home from './home.md';
import aboutMe from './aboutMe.md';
import errorMd from './error.md';
import inProgress from './inProgress.md';
import datenschutz from './datenschutz.md';
import impressum from './impressum.md';
import comingSoon from './comingSoon.md';
import womoTruckV1 from './womoTruckV1.md';
import map from '../../../components/pages/Map.js';
// import map from '../../../components/pages/map.js';
import test from './test.md';
import test2 from './test2.md';

export const contentObjectHome = {
  //==================================================================
  // // // Home
  home: {
    page: ['home', '/home'],
    displayName: 'Home',
    content: [{ type: 'md', content: home }],
    blogOmit: true,
    sidebarOrder: 'paypal',
    sidebar: [],
    tags: [],
  },
  about: {
    page: ['about-me', '/about-me'],
    displayName: 'About-Me',
    icon: '🚎',
    date: { created: '01.08.23' },
    content: [{ type: 'md', content: aboutMe }],
    blogOmit: false,
    tags: ['story'],
  },
  error: {
    page: ['404-page-not-found', '/404-page-not-found'],
    displayName: '404 Page not found',
    blogOmit: true,
    content: [{ type: 'md', content: errorMd }],
    tags: [],
  },
  inProgress: {
    page: ['in-progress', '/in-progress'],
    displayName: 'In Arbeit',
    content: [{ type: 'md', content: inProgress }],
    blogOmit: true,
    tags: [],
  },
  impressum: {
    page: ['impressum', '/impressum'],
    displayName: 'Impressum',
    content: [{ type: 'md', content: impressum }],
    blogOmit: true,
    tags: [],
  },
  datenschutz: {
    page: ['datenschutz', '/datenschutz'],
    displayName: 'Datenschutzerklärung',
    content: [{ type: 'md', content: datenschutz }],
    blogOmit: true,
    tags: [],
  },
  cookies: {
    page: ['cookies', '/cookies'],
    displayName: 'Cookies',
    content: [{ type: 'md', content: datenschutz }],
    blogOmit: true,
    tags: [],
  },
  comingSoon: {
    page: ['coming-soon', 'coming-soon'],
    displayName: 'Coming Soon',
    content: [{ type: 'md', content: comingSoon }],
    blogOmit: true,
    tags: [],
  },
  womoTruckV1: {
    page: ['unser-wohnmobil-truck', '/blog/unser-wohnmobil-truck'],
    displayName: 'Wohnmobil Truck',
    icon: '🚎',
    date: { created: '01.11.23' },
    content: [{ type: 'md', content: womoTruckV1 }],
    tags: ['womo', 'wohnmobil', 'ausgaben'],
  },
  test: {
    page: ['test', 'test'],
    displayName: 'test',
    blogOmit: true,
    offline: true,
    content: [{ type: 'md', content: test }],
    tags: [],
  },
  test2: {
    page: ['test2', 'test2'],
    displayName: 'test2',
    blogOmit: true,
    offline: true,
    content: [{ type: 'md', content: test2 }],
    tags: [],
  },
};
