import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './data/variables.css';
import { CookiesProvider } from 'react-cookie';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

// eslint-disable-next-line react/no-deprecated
import { hydrate } from 'react-dom'; //render
import ReactDOM from 'react-dom/client';

const APP = (
  <React.StrictMode>
    <CookiesProvider defaultSetOptions={{ path: '/' }}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />}></Route>
          <Route path="/:page" element={<App />}></Route>
          <Route path="/auszeit/:page" element={<App />}></Route>
          <Route path="/womo/:page" element={<App />}></Route>
          <Route path="/karte/:page" element={<App />}></Route>
          <Route path="/blog/:page" element={<App />}></Route>
          <Route path="/story/:page" element={<App />}></Route>
          <Route path="/technik/:page" element={<App />}></Route>
          <Route path="/social-media/:page" element={<App />}></Route>
          <Route path="/*" element={<App />}></Route>
        </Routes>
      </BrowserRouter>
    </CookiesProvider>
  </React.StrictMode>
);

// create root to normal react page render
const root = ReactDOM.createRoot(document.getElementById('root'));

// create rootElement to pre render
const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
  hydrate(APP, rootElement);
} else {
  // render(APP, rootElement); // react snap - render method
  root.render(APP);
}

reportWebVitals();
