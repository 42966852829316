import React, { useRef, useState, useEffect } from 'react';
// import ReactDOM from 'react-dom';
// import ReactDOMServer from 'react-dom/server';
import { Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import classes from './MarkerMyPos.module.css';
// import allBlogPosts from '../pages/AllBlogPosts.module.css';
import SocialMedia from '../../components/ui/SocialMediaVideo';
import { icon } from '../../data/mapData/mapIcons.js'; //icon = (string, iconSize, popupAnchor);
import { isValidUrl, stringClean } from '../../utils/mapFunctions.js';

import fbIcon from '../../assets/icon/flaticon/facebook.png';
import gmIcon from '../../assets/icon/flaticon/googleMaps.png';
import ttIcon from '../../assets/icon/flaticon/tt.png';
import ytIcon from '../../assets/icon/flaticon/yt.png';
import webIcon from '../../assets/icon/flaticon/web.png';

import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';
// Todo
// refactor logic
const MarkerComponent = props => {
  const markerRef = useRef();
  const [iconState, setIconState] = useState(icon('default'));
  const [mapsLink, setMapsLink] = useState(
    `https://maps.google.com/?q=${props.obj.geo[0]},${props.obj.geo[1]}`
  );
  const preview = props.obj.state === 'noPreview' ? false : true;

  const [fb, setFb] = useState(false);
  const [tiktok, setTiktok] = useState(false);
  const [youTube, setYouTube] = useState(false);
  const [web, setWeb] = useState(false);
  const [blog, setBlog] = useState(false);
  //==================================================================
  const map = useMap();
  const zoomIn = geo => {
    if (props.currentPos) map.setView(geo, 6);
  };
  zoomIn(props.obj.geo);
  //==================================================================

  useEffect(() => {
    if (props.currentPos) {
      setIconState(icon('current'));
      return;
    }
    if (props.obj.icon === 'fav') {
      setIconState(icon('star'));
      return;
    }
    if (props.future) {
      setIconState(icon('grey'));
      return;
    }
    setIconState(icon(props.obj.type));
  }, []);
  useEffect(() => {
    const urlMap = props.obj.maps.trim();
    const urlFb = stringClean(props.obj.fb);
    const urlTt = stringClean(props.obj.tt);
    const urlYt = stringClean(props.obj.yt);
    const urlWeb = stringClean(props.obj.web);
    const urlBlog = stringClean(props.obj.blog);
    if (isValidUrl(urlMap)) {
      setMapsLink(urlMap);
    }
    if (isValidUrl(urlFb)) {
      setFb(urlFb);
    }
    if (urlTt) {
      setTiktok(urlTt);
    }
    if (isValidUrl(urlYt)) {
      setYouTube(props.obj.yt);
    }
    if (isValidUrl(urlWeb)) {
      setWeb(urlWeb);
    }
    if (isValidUrl(urlBlog)) {
      setBlog(urlBlog);
    }
  }, []);
  //==================================================================
  // BUG first point
  // console.log(`✅`, props.obj.name);
  // if (props.obj.name.trim() === 'Debugging Wiesbaden') {
  //   console.log(`✅`, props.obj.geo[0], props.obj.geo[1]);
  // }
  //==================================================================
  return (
    <Marker
      icon={iconState}
      ref={markerRef}
      position={[props.obj.geo[0] || 50.0, props.obj.geo[1] || 50.0]}
      // eventHandlers={eventHandlers}
      className={classes.marker}
    >
      <Popup>
        <div className={classes.popupBox}>
          {props.currentPos && <h4>Zuletzt waren wir hier:</h4>}
          {props.obj.name && <h3>{props.obj.name}</h3>}
          {props.obj.type !== 'default' && (
            <p>
              Kategorie: <b>{props.obj.type}</b>
            </p>
          )}
          {props.obj.price && <h4>Preis: {props.obj.price}</h4>}
          {props.obj.description && (
            <div>
              <p>
                <b>Beschreibung:</b>
              </p>
              <p>{props.obj.description}</p>
            </div>
          )}
          {props.obj.remark && (
            <div>
              <p>Bemerkung:</p>
              <p>{props.obj.remark}</p>
            </div>
          )}
          {blog && (
            <div>
              <a href={blog} target="_blank" rel="noreferrer">
                <p>Weiter zum Blogbeitrag</p>
              </a>
            </div>
          )}
          <div className={classes.buttonTextBox}>
            <p>Weitere Informationen:</p>
            {/* <p>Unter den folgenden links findest du weitere Informationen</p> */}
            <div className={classes.buttonBox}>
              <motion.div
                whileHover={{
                  scale: 1.05,
                }}
                whileTap={{ scale: 1 }}
                className={` ${classes.icon}`}
              >
                <a href={mapsLink} target="_blank" rel="noreferrer">
                  <img
                    src={gmIcon}
                    alt="Google Maps"
                    className={`${classes.icon}`}
                  />
                </a>
              </motion.div>
              {youTube && (
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  className={` ${classes.icon}`}
                  // onClick={toggleFav}
                >
                  <a href={youTube} target="_blank" rel="noreferrer">
                    <img src={ytIcon} alt="You Tube" />
                  </a>
                </motion.div>
              )}
              {tiktok && (
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  className={` ${classes.icon}`}
                  // onClick={toggleFav}
                >
                  <a href={tiktok} target="_blank" rel="noreferrer">
                    <img src={ttIcon} alt="Tiktok" />
                  </a>
                </motion.div>
              )}
              {fb && (
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  className={` ${classes.icon}`}
                  // onClick={toggleFav}
                >
                  <a href={fb} target="_blank" rel="noreferrer">
                    <img src={fbIcon} alt="Facebook" />
                  </a>
                </motion.div>
              )}
              {web && (
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 1 }}
                  className={` ${classes.icon}`}
                  // onClick={toggleFav}
                >
                  <a href={web} target="_blank" rel="noreferrer">
                    <img src={webIcon} alt="Web-Link" />
                  </a>
                </motion.div>
              )}
            </div>
          </div>
          {youTube && preview && (
            <SocialMedia youTube={youTube} marker={true} instCaption="true" />
          )}
        </div>
      </Popup>
    </Marker>
  );
};

export default MarkerComponent;
