import React, { useState, useEffect, useRef } from 'react';
import {
  MapContainer,
  LayersControl,
  LayerGroup,
  TileLayer,
  Marker,
  Popup,
  useMap,
  Polyline,
} from 'react-leaflet';
import classes from './LeafletMyPos.module.css';
import { config } from '../../utils/constants';
import uuid from 'react-uuid';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import 'react-leaflet-fullscreen/styles.css';
//==================================================================
import allBlogPosts from '../pages/AllBlogPosts.module.css';
import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';
//==================================================================
import MarkerComponent from './MarkerMyPos';
import FetchCSVData from '../../utils/FetchCSVData';
//==================================================================
const sheetPath = {
  prod: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vRdWIk02GffmTM_kToQgWp1Us8S_7e6HdOEo4e3zH6RVS2ZkUypkD1Q5BS687g1RNFR-ck5ZufE7Wr8/pub?output=csv',
  dev: 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSBaaWtx49n8Mw_0fXvqRlLHSDMag8Khm9hLtZDN6sVSxp2K9wXsnhQ63cW_nfXOCuEY57TsHxJAbTq/pub?output=csv',
};

function LeafletMyPos(props) {
  //==================================================================
  // handle sheet data
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingKillTimer, setLoadingKillTimer] = useState(false);
  const getCurrentPosition = data.findIndex(el => el.state === 'x');
  const [polyline, setPolyline] = useState([]);
  const polylineColor = { color: 'teal' };
  const polylineFutureColor = { color: 'grey' };

  //==================================================================
  const fetchedData = FetchCSVData(config.dev ? sheetPath.dev : sheetPath.prod);
  const geoStrCleanUp = str => {
    if (!str) return;
    if (str) return str.replace(/"/gi, '').trim();
  };
  //==================================================================
  //==================================================================
  useEffect(() => {
    console.log(`✅`, fetchedData);
    if (fetchedData.error) {
      setLoading(false);
      setLoadingKillTimer(true);
      return;
    }
    const cleanData = data => {
      return data.map(e => {
        e.geo = [geoStrCleanUp(e.lat), geoStrCleanUp(e.long)];
        return e;
      });
    };
    const filterNoGeoAndOffline = clean => {
      return clean
        .filter(e => e.geo[0] !== undefined && e.geo[1] !== undefined)
        .filter(e => e.state !== 'offline');
    };
    // console.log(`✅`, filterNoGeoAndOffline(cleanData(fetchedData)));
    // console.log(`✅`, fetchedData);
    setData(filterNoGeoAndOffline(cleanData(fetchedData)));
  }, [fetchedData]);
  //=========================
  useEffect(() => {
    setPolyline(pre => {
      var arPast = [];
      var arFuture = [];
      data?.map((e, i) => {
        if (i >= getCurrentPosition) {
          arFuture.push(e.geo);
        }
        if (i <= getCurrentPosition) {
          arPast.push(e.geo);
        }
        return;
      });
      return { past: [...arPast], future: [...arFuture] };
    });
    if (data.length > 0) {
      setLoading(false);
      setLoadingKillTimer(false);
    }
  }, [data]);

  //=========================

  //==================================================================
  const initialPosition = [49.8, 8.54];
  const initialZoom = 5;
  //==================================================================
  //==================================================================
  const createClusterCustomIcon = function (cluster) {
    var markers = cluster.getAllChildMarkers();
    var n = 0;
    for (var i = 0; i < markers.length; i++) {
      n = markers.length;
    }
    var small = n < 999;
    var cssSize = small ? classes.cssSmall : classes.cssLarge;
    var size = small ? 40 : 60;
    return L.divIcon({
      html: `<span>${cluster.getChildCount()}</span>`,
      className: `${cssSize} ${classes.customMarkerCluster}`,
      iconSize: L.point(size, size, true),
    });
  };

  //==================================================================
  //==================================================================
  return (
    <>
      <div className={classes.box}>
        <MapContainer
          id="map"
          zoom={initialZoom}
          center={initialPosition}
          className={`${classes.leafletContainer}`}
          // className={classes.leafletContainer}
          scrollWheelZoom={true}
          attributionControl={false} //leaflet logo
        >
          {loading && <div className={`${classes.loader}`}></div>}
          {loadingKillTimer && (
            <div className={`${classes.loaderKill}`}>
              Die Daten konnten nicht geladen werden !
              {/* <br />
              Bitte Reload versuchen oder später nochmal versuchen. */}
              <motion.button
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 1 }}
                className={allBlogPosts.tags}
                onClick={() => {
                  location.reload();
                }}
              >
                Daten laden - Reload now
              </motion.button>
            </div>
          )}
          <LayersControl position="topright">
            <LayersControl.BaseLayer checked name="Open Street Map">
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                maxZoom={19}
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
            </LayersControl.BaseLayer>
            <LayersControl.BaseLayer name="Google Maps Satellite">
              <LayerGroup>
                <TileLayer
                  attribution="Google Maps Satellite"
                  url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                  // maxZoom={10}
                  subdomains={['mt1', 'mt2', 'mt3']}
                />
                <TileLayer url="https://www.google.cn/maps/vt?lyrs=y@189&gl=cn&x={x}&y={y}&z={z}" />
              </LayerGroup>
            </LayersControl.BaseLayer>

            <LayersControl.BaseLayer name="Google Maps Street">
              <TileLayer
                attribution="Google Maps"
                url="https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}"
                // maxZoom={9}
              />
            </LayersControl.BaseLayer>
          </LayersControl>
          {
            <MarkerClusterGroup
              chunkedLoading
              // onClick={e => console.log('onClick', e)}
              iconCreateFunction={createClusterCustomIcon}
              maxClusterRadius={50}
              disableClusteringAtZoom={5}
              spiderfyOnMaxZoom={false}
              zoomToBoundsOnClick={true}
              animate={true}
              polygonOptions={{
                fillColor: '#e2e2e288',
                color: '#e7534e',
                weight: 5,
                opacity: 1,
                fillOpacity: 0.8,
              }}
              showCoverageOnHover={true}
            >
              {data.map((el, i) => (
                <MarkerComponent
                  obj={el}
                  currentPos={el.state === 'x' ? true : false}
                  future={i > getCurrentPosition ? true : false}
                  key={el.id}
                  mapProps={props}
                ></MarkerComponent>
              ))}
            </MarkerClusterGroup>
          }
          <Polyline
            pathOptions={polylineColor}
            positions={polyline.past || []}
          />
          <Polyline
            pathOptions={polylineFutureColor}
            positions={polyline.future || []}
          />
        </MapContainer>
      </div>
    </>
  );
}

export default LeafletMyPos;
