//==================================================================
import map from '../../components/pages/Map.js';
import mapMyPos from '../../components/pages/MapMyPos.js';
// import map from '../../../components/pages/map.js';

export const contentObjectMap = {
  karte: {
    page: ['karte', '/karte'],
    displayName: 'Map/Karte',
    icon: '👉',
    sidebarOff: true,
    maxWidth: '100%',
    blogOmit: true,
    content: [{ type: 'react', content: map }],
    tags: [],
  },
  karte2: {
    page: ['stellplätze-für-große-womos', '/karte/stellplätze-für-große-womos'],
    displayName: 'Map/Karte',
    icon: '👉',
    sidebarOff: true,
    maxWidth: '100%',
    blogOmit: true,
    content: [{ type: 'react', content: map }],
    tags: [],
  },
  myPos: {
    page: ['unsere-route', '/karte/unsere-route'],
    displayName: 'Unsere Route',
    icon: '👉',
    sidebarOff: true,
    maxWidth: '100%',
    offline: false,
    blogOmit: true,
    content: [{ type: 'react', content: mapMyPos }],
    tags: [],
  },
};
