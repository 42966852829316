import React from 'react';
import classes from './Footer.module.css';
import Social from '../ui/Social';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className={classes.footerColor}>
      <div className={classes.footer}>
        <div className={classes.divIcon}>
          <Social></Social>
        </div>
        <div className={classes.linkDiv}>
          <Link to={'/impressum'} id={'impressum'} className={classes.link}>
            Impressum
          </Link>
          <Link to={'/datenschutz'} id={'datenschutz'} className={classes.link}>
            Datenschutzerklärung
          </Link>
          <p>
            Icons auf dieser Homepage von &nbsp;
            <a
              href="https://www.flaticon.com"
              target="_blank"
              rel="noreferrer"
              // className={classes.link}
            >
              Flaticon &nbsp;
            </a>
            und &nbsp;
            <a
              href="https://www.freepik.com"
              target="_blank"
              rel="noreferrer"
              // className={classes.link}
            >
              Freep!k
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
