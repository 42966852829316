import React, { useRef, useMemo, useState, useEffect } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

import classes from './Marker.module.css';
import allBlogPosts from '../pages/AllBlogPosts.module.css';

import L from 'leaflet';
import star from '../../assets/mapImg/icon/star.svg';
import markerIcon from '../../assets/mapImg/icon/marker.svg';
import xlMarker from '../../assets/mapImg/icon/xlMarker.png';

// icon
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import { AnimatePresence, motion, useAnimationControls } from 'framer-motion';

const markerXL = new L.Icon({
  iconUrl: xlMarker,
  iconRetinaUrl: xlMarker,
  popupAnchor: [-0, -0],
  iconSize: [40, 40],
});
const markerStar = new L.Icon({
  iconUrl: star,
  iconRetinaUrl: star,
  popupAnchor: [-0, -0],
  iconSize: [40, 40],
});

const MarkerComponent = props => {
  // console.log(`✅`, props);
  const markerRef = useRef();
  // const [favList, setFavList] = useState(props.mapProps.favList);
  const favList = props.mapProps.favList;
  const [starChecked, setStarChecked] = useState(
    favList.some(el => el == props.obj.id)
  );
  //==================================================================
  const toggleFav = () => {
    if (starChecked) {
      // remove item from list
      props.mapProps.setFavList(favList.filter(el => el != props.obj.id));
      setStarChecked(false);
    }
    if (!starChecked) {
      // add item to list
      props.mapProps.setFavList([...favList, props.obj.id]);
      setStarChecked(true);
    }
  };
  //==================================================================
  // const eventHandlers = useMemo(
  //   () => ({
  //     mouseover() {
  //     },
  //     // mouseout() {
  //     // },
  //     click() {
  //       if (markerRef) markerRef.current.openPopup();
  //     },
  //   }),
  //   [props.zoomLevel]
  // );
  //==================================================================
  const map = useMap();
  const zoomIn = () => {
    map.setView([props.obj.geo[0], props.obj.geo[1]], 18);
    markerRef.current.closePopup();
  };
  const zoomOut = () => {
    map.setView([props.obj.geo[0], props.obj.geo[1]], 9);
    markerRef.current.closePopup();
  };
  //==================================================================
  return (
    <Marker
      // icon={markerPoint}
      icon={starChecked ? markerStar : markerXL}
      ref={markerRef}
      position={[props.obj.geo[0] || 50.0, props.obj.geo[1] || 50.0]}
      // eventHandlers={eventHandlers}
      className={classes.marker}
    >
      <Popup>
        <div>
          <h2>{props.obj.name}</h2>
          <h4>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${props.obj.geo.lat},${props.obj.geo.long}`}
              target="_blank"
              rel="noreferrer"
            >
              Geodaten auf Google Maps öffnen
              {/* Lat: {el.obj.geo.lat} Long:{' '} */}
              {/* {el.obj.geo.long} */}
            </a>
          </h4>
          <h4>
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${props.obj.geo.lat},${props.obj.geo.long}+${props.obj.name}`}
              target="_blank"
              rel="noreferrer"
            >
              Platzname auf Google Maps suchen
            </a>
          </h4>
          <div className={classes.buttonBox}>
            <motion.div
              whileHover={{ scale: 1.03 }}
              whileTap={{ scale: 1 }}
              className={`${allBlogPosts.tags} ${allBlogPosts.tagsIcon}`}
              onClick={toggleFav}
            >
              <FontAwesomeIcon
                className={starChecked ? classes.starChecked : ''}
                icon={faStar}
              />
            </motion.div>
            <button className={allBlogPosts.tags} onClick={zoomIn}>
              ZOOM IN
            </button>
            <button className={allBlogPosts.tags} onClick={zoomOut}>
              ZOOM OUT
            </button>
          </div>
        </div>
      </Popup>
    </Marker>
  );
};

export default MarkerComponent;
