import React, { useState, useEffect } from 'react';
import classes from './SocialMediaVideo.module.css';
import { markdown } from '../../utils/markdown.js';
import {
  YouTubeEmbed,
  TikTokEmbed,
  InstagramEmbed,
  FacebookEmbed,
} from 'react-social-media-embed';
import useWindowDimensions from '../../hooks/useWindowDimensions.js';

// export const youTubeAddId = id => {
//   return `https://youtu.be/${id}`;
// };

export const youTubeLinkToIdPath = link => {
  if (link.includes('https://youtu.be')) {
    // Share https://youtu.be/u9cKLFGRSp8?si=TVzQdhK7-9DV_P2P
    const [url, rest] = link.split('?si=');
    return url;
  }
  if (link.includes('?v=')) {
    // https://www.youtube.com/watch?v=u9cKLFGRSp8
    const [url, id] = link.split('?v=');
    return `https://youtu.be/${id}`;
  }
  console.log(`Check YouTube link: `, link);
};
const SocialMedia = props => {
  const { height, width } = useWindowDimensions();
  //==================================================================
  const youTubeWidth = props.marker ? 270 : width > 900 ? 780 : 500;
  const youTubeHeight = props.marker ? 150 : width > 900 ? 439 : 281;
  //==================================================================
  const instCaption = input => {
    if (input)
      return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <InstagramEmbed url={props.instagram} width={328} captioned />
        </div>
      );
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <InstagramEmbed url={props.instagram} width={328} />
        test
      </div>
    );
  };
  //==================================================================
  return (
    <div
      className={`${classes.highLightBox} ${
        !props.roundedOff && classes.rounded
      } `}
      id={`${props.id}`}
    >
      {props.text && <div>{markdown(props.text, true, '')}</div>}
      {props.tiktok && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <TikTokEmbed url={props.tiktok} width={325} />
        </div>
      )}
      {props.youTube && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <YouTubeEmbed
            url={youTubeLinkToIdPath(props.youTube)}
            width={youTubeWidth}
            height={youTubeHeight}
          />
          {/*Aspect Ratio = 1,775956284153005 */}
        </div>
      )}
      {props.instagram && instCaption(props.instCaption)}
      {props.facebook && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <FacebookEmbed url={props.facebook} width={550} />
        </div>
      )}
    </div>
  );
};

export default SocialMedia;
